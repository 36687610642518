import React, { useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { API } from "../api/API";
import { Context } from "../Context";

const ADMIN_LINKS = [
  { text: "Dashboard", icon: "house", link: "/" },
  // { text: "Help", icon: "book", link: "/help" },
  // { text: "Statistics", icon: "chart-line", link: "/statistics" },
  { text: "Company", icon: "building", link: "/companies/{companyId}" },
  // {
  //   text: "Billing",
  //   icon: "file-invoice-dollar",
  //   link: "/companies/{companyId}/billing",
  // },
  { text: "Devices", icon: "mobile-screen", link: "/devices" },
];

const GAME_LINKS = [
  { text: "Games", icon: "trophy", link: "/games" },
  { text: "Templates", icon: "copy", link: "/templates" },
  { text: "Blocks", icon: "cube", link: "/blocks" },
  { text: "Missions", icon: "clipboard-question", link: "/missions" },
  { text: "Maps", icon: "map-location-dot", link: "/maps" },
];

export const Sidebar = () => {
  const location = useLocation();
  const context = useContext(Context);

  const hasSetupAdminKitSidebarRef = useRef(false);

  useEffect(() => {
    if (!hasSetupAdminKitSidebarRef.current) {
      const sidebar = document.getElementsByClassName("js-sidebar")[0];

      const sidebarToggle =
        document.getElementsByClassName("js-sidebar-toggle")[0];

      sidebarToggle.addEventListener("click", () => {
        const sidebar = document.getElementsByClassName("js-sidebar")[0];
        sidebar.classList.toggle("collapsed");
        sidebar.addEventListener("transitioned", () => {
          window.dispatchEvent(new Event("resize"));
        });
      });

      hasSetupAdminKitSidebarRef.current = true;
    }
  }, []);

  const makeItems = (links: any[]) => {
    if (!context?.user) {
      return null;
    }

    return links.map((link) => (
      <li
        className={`sidebar-item ${
          location.pathname === link.link ? "active" : ""
        }`}
        key={link.link}
      >
        <Link
          className="sidebar-link"
          to={link.link.replace(
            "{companyId}",
            context!.user!.company_id.toString()
          )}
        >
          <i className={`fa-solid fa-${link.icon} align-middle`}></i>{" "}
          <span className="align-middle">{link.text}</span>
        </Link>
      </li>
    ));
  };

  if (!context?.user || !context?.company) {
    return null;
  }

  return (
    <nav id="sidebar" className="sidebar js-sidebar">
      <div className="sidebar-content js-simplebar">
        <Link className="sidebar-brand" to="/">
          <img
            src="/logo192.png"
            className="align-middle me-3"
            style={{ width: 48, borderRadius: 6 }}
          />
          <span className="align-middle">Cajam Games</span>
        </Link>

        <ul className="sidebar-nav">
          <li className="sidebar-header fw-bold">{context.user.name}</li>
          <li className="sidebar-header pt-0 pb-0">{context.company.name}</li>
          <a className="sidebar-link" href="#" onClick={API.logout}>
            <i className={`fa-solid fa-right-from-bracket align-middle`}></i>{" "}
            <span className="align-middle">Sign Out</span>
          </a>

          <li className="sidebar-header">Admin</li>
          {makeItems(ADMIN_LINKS)}

          <li className="sidebar-header">Games</li>
          {makeItems(GAME_LINKS)}
        </ul>

        <span className="text-sm ms-4 mb-4">
          Copyright &copy; 2023 Cajam Event AB
        </span>

        {/*<div className="sidebar-cta">*/}
        {/*  <div className="sidebar-cta-content">*/}
        {/*    */}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </nav>
  );
};
