import React, { useEffect, useState } from "react";
import { AppVersion } from "../models/AppVersion";
import { getApkDownloadUrl, getLatestAppVersion } from "../api/App";
import { formatIsoDate } from "../utils/DateUtils";
import { NewsPostsTable } from "../components/NewsPostsTable";
import { Breadcrumb } from "../components/Breadcrumb";

export const HomePage = () => {
  const [latestAppVersion, setLatestAppVersion] = useState<AppVersion>();

  useEffect(() => {
    getLatestAppVersion().then(setLatestAppVersion);
  }, []);

  return (
    <>
      <Breadcrumb items={[{ text: "Home", link: "/" }]} />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="card-title mb-0">News</h5>
            </div>
            <div className="card-body">
              <NewsPostsTable />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0">
                  <h5 className="card-title mb-0">Contact & Support</h5>
                </div>
                <div className="card-body">
                  <div>
                    <strong>Cajam Event</strong>
                    <br />
                    <div className="ms-3">
                      <a
                        href="https://cajamevent.se/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        cajamevent.se
                      </a>
                    </div>
                  </div>
                  <div className="mt-3">
                    <strong>Sales & Tech Support - Adam Ericsson</strong>
                    <br />
                    <div className="ms-3">
                      <a href="tel:+46455615472">0455-61 54 72</a>
                      <br />
                      <a href="mailto:adam.ericsson@cajam.se">
                        adam.ericsson@cajam.se
                      </a>
                    </div>
                  </div>
                  {/*<div className="mt-3">TODO: Legal info</div>*/}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0">
                  <h5 className="card-title mb-0">Download</h5>
                </div>
                <div className="card-body">
                  {!!latestAppVersion && (
                    <div>
                      <a
                        href={getApkDownloadUrl(latestAppVersion.id)}
                        className="btn btn-primary btn-lg"
                      >
                        <i className="fa-solid fa-download"></i> Download Cajam
                        Games APK
                      </a>
                      <p>
                        <small>
                          Version <strong>{latestAppVersion.version}</strong>{" "}
                          released {formatIsoDate(latestAppVersion.created_at)}.
                          Requires Android{" "}
                          {latestAppVersion.required_android_version} or newer.
                        </small>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="col-12 col-md-6">*/}
        {/*  <div className="card bg-secondary">*/}
        {/*    <div className="card-header">Todo list</div>*/}
        {/*    <div className="card-body">*/}
        {/*      <p>TODO: Implement. This is fake data.</p>*/}
        {/*      <TodoTable />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  );
};
