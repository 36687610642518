import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Game } from "../models/Game";
import { loadGame } from "../api/Game";
import { CircleMarker, MapContainer, TileLayer, Tooltip } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { DeviceStatus } from "../models/DeviceStatus";
import { getDeviceStatusByDevice } from "../api/DeviceStatus";
import { Device } from "../models/Device";
import {
  loadDevice,
  loadDeviceParticipations,
  updateDeviceParticipation,
} from "../api/Device";
import { loadAnswers } from "../api/Answer";
import { loadMissions } from "../api/Mission";
import { loadMapMissions } from "../api/MapMission";
import { Photo } from "../models/Photo";
import { approvePhoto, getPhotoUrl, loadPhotoList } from "../api/Photo";
import { listSMSByGameAndDevice, sendSMSToDevice } from "../api/SMS";
import { Template } from "../models/Template";
import { Map } from "../models/Map";
import { loadTemplate } from "../api/Template";
import { loadMap } from "../api/Map";
import { Breadcrumb } from "../components/Breadcrumb";
import { DeviceParticipation } from "../models/DeviceParticipation";
import { cropString } from "../utils/StringUtils";
import { Variable } from "../models/Variable";
import { loadVariablesByGame } from "../api/Variable";
import { toast } from "react-toastify";

const UPDATE_INTERVAL = 10 * 1000;
const ANSWER_POLL_INTERVAL = 10 * 1000;
const MISSION_TEXT_MAX_LENGTH = 100;

interface FollowListItem {
  icon: string;
  id: string;
  text: string;
  answer: string;
  time: string;
  className: string;
}

enum Tab {
  Teams,
  Map,
  Photos,
  SMS,
  Variables,
}

export const GameFollowPage = () => {
  const [tab, setTab] = useState<Tab>(Tab.Teams);
  const [game, setGame] = useState<Game>();
  const [template, setTemplate] = useState<Template>();
  const [map, setMap] = useState<Map>();
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>();
  const [statuses, setStatuses] = useState<DeviceStatus[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceParticipations, setDeviceParticipations] = useState<
    DeviceParticipation[]
  >([]);
  const [variables, setVariables] = useState<Variable[]>([]);
  const [loading, setLoading] = useState(false);
  const [sms, setSms] = useState("");
  const [uncheckedPhotos, setUncheckedPhotos] = useState<Photo[]>([]);
  const [checkedPhotoIds, setCheckedPhotoIds] = useState<number[]>([]);
  const [showAnswers, setShowAnswers] = useState(false);
  const [activeDeviceId, setActiveDeviceId] = useState<number>();
  const [activeItems, setActiveItems] = useState<FollowListItem[]>([]);
  const [missionTexts, setMissionTexts] = useState<{ [key: number]: string }>(
    {}
  );
  const [mapMissionTexts, setMapMissionTexts] = useState<{
    [key: number]: string;
  }>({});
  const [missionShortTexts, setMissionShortTexts] = useState<{
    [key: number]: string;
  }>({});
  const [mapMissionShortTexts, setMapMissionShortTexts] = useState<{
    [key: number]: string;
  }>({});
  const answerPollInterval = useRef<NodeJS.Timer>();
  const updatePollInterval = useRef<NodeJS.Timer>();
  const { id } = useParams();

  const formatDate = (date: string) => {
    return new Date(date).toLocaleString("sv-SE", {
      timeZone: "Europe/Stockholm",
    });
  };

  const sendSms = () => {
    if (!id || !sms.trim()) {
      return;
    }

    Promise.all(
      devices.map((device) => sendSMSToDevice(id, device.id, sms.trim()))
    )
      .then(() => {
        setSms("");
        toast.success("SMS sent.");
      })
      .catch((e) => {
        console.log("######", e);
        toast.error("Failed to send SMS.");
      });
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    loadGame(id).then((loadedGame) => {
      setGame(loadedGame);

      loadTemplate(loadedGame.template_id.toString()).then((loadedTemplate) => {
        setTemplate(loadedTemplate);

        loadMap(loadedTemplate.map_id.toString()).then(setMap);
      });
    });

    loadMissions().then((res) => {
      const texts: { [key: number]: string } = {};
      const shortTexts: { [key: number]: string } = {};

      for (const mission of res) {
        texts[mission.id] = mission.text;
        shortTexts[mission.id] = mission.short_text;
      }

      setMissionTexts(texts);
      setMissionShortTexts(shortTexts);
    });

    loadMapMissions().then((res) => {
      const texts: { [key: number]: string } = {};
      const shortTexts: { [key: number]: string } = {};

      for (const mission of res) {
        texts[mission.id] = mission.text;
        shortTexts[mission.id] = mission.short_text;
      }

      setMapMissionTexts(texts);
      setMapMissionShortTexts(shortTexts);
    });
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      if (loading || !id) {
        return;
      }

      setLoading(true);

      const participations = await loadDeviceParticipations(parseInt(id, 10));
      setDeviceParticipations(participations);

      const devices = await Promise.all(
        participations.map((participation) =>
          loadDevice(participation.device_id)
        )
      );
      setDevices(devices);

      const statuses = await Promise.all(
        participations.map((participation) =>
          getDeviceStatusByDevice(participation.device_id)
        )
      );
      setStatuses(statuses);

      const variables = await loadVariablesByGame(parseInt(id, 10));
      setVariables(variables);

      const photos = await loadPhotoList(id);
      setUncheckedPhotos(
        photos.filter(
          (photo) => !photo.checked && !checkedPhotoIds.includes(photo.id)
        )
      );

      setLastUpdate(new Date());
      setLoading(false);
    };

    loadData();

    if (updatePollInterval.current) {
      clearInterval(updatePollInterval.current);
    }

    updatePollInterval.current = setInterval(() => {
      loadData();
    }, UPDATE_INTERVAL);
  }, []);

  useEffect(() => {
    const loadAllAnswers = async () => {
      if (!id || !activeDeviceId) {
        return;
      }

      const missionAnswers = await loadAnswers(id, activeDeviceId);
      const missionAnswerItems: FollowListItem[] = missionAnswers.map(
        (answer) => ({
          icon: answer.is_map
            ? "fa-solid fa-map-location-dot"
            : "fa-regular fa-circle-question",
          id: answer.mission_id,
          text: getMissionText(
            parseInt(answer.mission_id, 10),
            !!answer.is_map
          ),
          answer: `${answer.answer} [${answer.score > 0 ? "+" : ""}${
            answer.score
          }p]`,
          time: answer.created_at || "",
          className: answer.correct ? "success" : "danger",
        })
      );

      const sms = await listSMSByGameAndDevice(id, activeDeviceId);
      const smsItems: FollowListItem[] = sms.map((sms) => ({
        icon: "fa-regular fa-envelope",
        id: "SMS",
        text: sms.text,
        answer: sms.seen ? "Seen" : "Not seen",
        time: sms.created_at || "",
        className: sms.seen ? "success" : "info",
      }));

      const items = [...missionAnswerItems, ...smsItems].sort((a, b) =>
        a.time < b.time ? 1 : -1
      );

      setActiveItems(items);
    };

    if (answerPollInterval.current) {
      clearInterval(answerPollInterval.current);
    }

    if (!activeDeviceId) {
      return;
    }

    loadAllAnswers();

    setInterval(loadAllAnswers, ANSWER_POLL_INTERVAL);
  }, [activeDeviceId]);

  const renderAge = (updated: string) => {
    const updatedTimestamp = new Date(updated).getTime();
    const ageMs = Date.now() - updatedTimestamp;
    const ageMinutes = Math.min(Math.floor(ageMs / 1000 / 60), 60);
    const ageStr = `${ageMinutes >= 60 ? ">" : ""}${ageMinutes}m`;

    let ageClass = "success";
    if (ageMinutes > 5) ageClass = "warning";
    if (ageMinutes > 10) ageClass = "danger";

    return (
      <span className={`px-2 py-1 float-end badge bg-${ageClass}`}>
        {ageStr}
      </span>
    );
  };

  const getDeviceDisplayName = (deviceId: string) => {
    const device = devices.find((device) => device.device_id === deviceId);

    if (device) {
      const cleanPhoneNumber = device.phone_number.replace(/\D/g, "");
      return `${device.name} (${cleanPhoneNumber || "NO PHONENUMBER"})`;
    }

    return deviceId || "[UNKNOWN]";
  };

  const getDeviceDisplayNameShort = (id: number | undefined) => {
    const device = devices.find((device) => device.id === id);

    if (device) {
      return device.name;
    }

    return "[UNKNOWN]";
  };

  const getMissionText = (missionId: number, isMap: boolean) => {
    return (isMap ? mapMissionTexts : missionTexts)[missionId] || "";
  };

  const getMissionShortText = (missionId: number, isMap: boolean) => {
    if (isMap) {
      return mapMissionShortTexts[missionId] || mapMissionTexts[missionId];
    }

    return missionShortTexts[missionId] || missionTexts[missionId];
  };

  const approveImage = (photoId: number, approved: boolean) => {
    approvePhoto(photoId, approved).then(() => {
      setCheckedPhotoIds([...checkedPhotoIds, photoId]);
      setUncheckedPhotos(
        uncheckedPhotos.filter((photo) => photo.id !== photoId)
      );
    });
  };

  const promptBonusScore = (deviceId: string) => {
    const scoreStr = prompt(
      "Enter bonus score to add. Can be negative to remove points.",
      "0"
    );

    const score = parseInt(scoreStr || "", 10);
    const device = devices.find((device) => device.device_id === deviceId);

    if (isNaN(score) || !device || !id) {
      return;
    }

    const current = deviceParticipations.find(
      (participation) => participation.device_id === device.id
    );

    if (!current) {
      return;
    }

    updateDeviceParticipation(current.id, {
      bonus_score: current.bonus_score + score,
    });
  };

  const getScore = (participation: DeviceParticipation | undefined | null) => {
    if (!participation) {
      return 0;
    }

    return (participation.score || 0) + (participation.bonus_score || 0);
  };

  const getParticipationByDeviceId = (deviceId: string) => {
    const device = devices.find((device) => device.device_id === deviceId);

    if (!device) {
      return null;
    }

    return deviceParticipations.find(
      (participation) => participation.device_id === device.id
    );
  };

  const renderTabs = () => {
    return (
      <div className="col-12 col-md-6">
        <div className="btn-group">
          <button
            type="button"
            onClick={() => setTab(Tab.Teams)}
            className={`btn btn-lg ${
              tab === Tab.Teams ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Teams
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.Map)}
            className={`btn btn-lg ${
              tab === Tab.Map ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Map
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.Photos)}
            className={`btn btn-lg ${
              tab === Tab.Photos ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Photos
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.SMS)}
            className={`btn btn-lg ${
              tab === Tab.SMS ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            SMS
          </button>
          <button
            type="button"
            onClick={() => setTab(Tab.Variables)}
            className={`btn btn-lg ${
              tab === Tab.Variables ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            Variables
          </button>
        </div>
      </div>
    );
  };

  const renderTeams = () => {
    return (
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>Team</th>
                <th>Score</th>
                <th>Current Mission</th>
                <th>Device</th>
                <th>Show</th>
              </tr>
            </thead>
            <tbody>
              {statuses?.map((status) => {
                const participation = getParticipationByDeviceId(
                  status.device_id
                );

                return (
                  <tr key={status.id}>
                    <td>{participation?.team_name}</td>
                    <td>
                      {getScore(participation)}
                      <button
                        type="button"
                        className="btn btn-primary btn-sm float-end"
                        style={{ marginTop: -5 }}
                        onClick={() => promptBonusScore(status.device_id)}
                      >
                        +
                      </button>
                    </td>
                    <td>
                      {participation?.current_mission_id
                        ? cropString(
                            getMissionShortText(
                              participation?.current_mission_id,
                              false
                            ),
                            MISSION_TEXT_MAX_LENGTH
                          )
                        : ""}
                    </td>
                    <td>
                      {getDeviceDisplayName(status.device_id)}{" "}
                      {renderAge(status.updated_at || status.created_at)}
                    </td>
                    <td className="p-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          const device = devices.find(
                            (device) => device.device_id === status.device_id
                          );

                          if (!device) {
                            return;
                          }

                          if (device.id === activeDeviceId) {
                            setActiveDeviceId(undefined);
                            setShowAnswers(false);
                            return;
                          }

                          setActiveDeviceId(device.id);
                          setShowAnswers(true);
                        }}
                      >
                        <i className="fa-solid fa-bars-staggered"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderMap = () => {
    return (
      <div className="row">
        <div className="col-12">
          {!!map && (
            <MapContainer
              center={[map.home_longitude, map.home_latitude]}
              zoom={14}
              style={{ height: 600 }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {statuses
                ?.filter((status) => !!status.latitude && !!status.longitude)
                .map((status) => {
                  return (
                    <CircleMarker
                      key={status.id}
                      center={[status.latitude, status.longitude]}
                      pathOptions={{
                        color: "red",
                        fill: true,
                        fillOpacity: 1.0,
                      }}
                      radius={5}
                    >
                      <Tooltip
                        direction="top"
                        offset={[0, -3]}
                        opacity={1.0}
                        permanent
                      >
                        {getParticipationByDeviceId(status.device_id)
                          ?.team_name || ""}
                      </Tooltip>
                    </CircleMarker>
                  );
                })}
            </MapContainer>
          )}
        </div>
      </div>
    );
  };

  const renderSMS = () => {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6">
            <textarea
              className="w-100"
              rows={5}
              maxLength={150}
              onChange={(evt) => setSms(evt.target.value)}
              value={sms}
            />
          </div>
          <div className="col-md-6">{sms.length}/150</div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              disabled={!sms.trim()}
              onClick={() => sendSms()}
            >
              Skicka SMS
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderPhotos = () => {
    return (
      <div className="row mt-3 mb-5">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              Photos to check ({uncheckedPhotos.length})
            </div>
            <div className="card-body d-flex flex-row overflow-auto">
              {uncheckedPhotos.map((photo) => (
                <div className="unchecked-photo-container">
                  <img src={getPhotoUrl(id!, photo.file_name)} />
                  <br />
                  <span>{getMissionText(photo.mission_id, false)}</span>
                  <br />
                  <button
                    type="button"
                    className="btn btn-success m-1"
                    onClick={() => approveImage(photo.id, true)}
                  >
                    <i className="fa-solid fa-check"></i> Godkänn
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger m-1"
                    onClick={() => approveImage(photo.id, false)}
                  >
                    <i className="fa-solid fa-check"></i> Underkänn
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVariables = () => (
    <div className="row">
      <div className="col-12">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Team</th>
              <th>Variables</th>
            </tr>
          </thead>
          <tbody>
            {deviceParticipations.map((participation) => (
              <tr>
                <td>{participation.team_name}</td>
                <td>
                  <table className="table table-bordered table-sm mb-0">
                    <tbody>
                      {variables
                        .filter(
                          (variable) =>
                            variable.device_id === participation.device_id
                        )
                        .map((variable) => (
                          <tr>
                            <td>{variable.key}</td>
                            <td>{variable.value}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb
        items={[
          { text: "Games", link: "/games" },
          { text: game?.name || "", link: `/games/${game?.id}` },
          { text: "Follow", link: `/games/${game?.id}/follow` },
        ]}
      />
      <div className="row mb-3">
        {renderTabs()}
        <div className="col-12 col-md-6">
          <p className="float-end">
            Last update:{" "}
            {lastUpdate?.toLocaleString("sv-SE", {
              timeZone: "Europe/Stockholm",
            })}
          </p>
        </div>
      </div>
      <div className="row">
        <div className={showAnswers ? "col-8" : "col-12"}>
          {tab === Tab.Teams && renderTeams()}
          {tab === Tab.Map && renderMap()}
          {tab === Tab.Photos && renderPhotos()}
          {tab === Tab.SMS && renderSMS()}
          {tab === Tab.Variables && renderVariables()}
        </div>
        {showAnswers && (
          <div className="col-4">
            <br />
            <div className="card mt-3">
              <div className="card-header">
                {getDeviceDisplayNameShort(activeDeviceId)}
              </div>
              <div className="card-body">
                {activeItems.map((item) => (
                  <>
                    <div className="alert alert-primary p-2 mb-1">
                      <p className="mb-0">
                        <small>
                          <i className={item.icon}></i> {item.id} - {item.text}
                        </small>
                      </p>
                    </div>
                    <div className={`alert p-2 alert-${item.className}`}>
                      <p className="mb-0" style={{ textAlign: "right" }}>
                        <small>{item.answer}</small>
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
