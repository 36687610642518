import { API } from "./API";
import { Template } from "../models/Template";
import { Page } from "./Page";

export const loadTemplate = (id: string): Promise<Template> => {
  return API.get<Template>(`template/${id}`);
};

export const loadTemplates = (): Promise<Template[]> => {
  return API.get<Template[]>("template");
};

export const loadTemplatesPage = (
  page?: number,
  size?: number,
  sort?: string,
  dir?: string,
  filter?: string
): Promise<Page<Template>> => {
  return API.get<Page<Template>>(
    "template/page?" +
      API.buildQueryParams({
        page,
        size,
        sort,
        dir,
        filter,
      })
  );
};

export const updateTemplate = (template: Template): Promise<void> => {
  return API.put<void>(`template/${template.id}`, template);
};

export const createTemplate = (template: Template): Promise<Template> => {
  return API.post<Template>("template", template);
};
