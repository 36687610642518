import {API, BASE_URL} from "./API";
import {Photo} from "../models/Photo";

export const loadPhotoList = (gameId: string): Promise<Photo[]> => {
    return API.get<Photo[]>(`photo/${gameId}`);
}

export const approvePhoto = (photoId: number, approved: boolean): Promise<void> => {
    return API.put<void>(`photo/${photoId}`, {approved});
}

export const getPhotoUrl = (gameId: string, fileName: string): string =>
    `${BASE_URL}photo/${gameId}/${encodeURIComponent(fileName)}`;

export const getPhotoZipUrl = (gameId: string): string =>
    `${BASE_URL}photo/${gameId}/zip`;
